import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)/(home)": [~5,[2],[3]],
		"/(app)/auth/account": [~6,[2],[3]],
		"/(app)/auth/email-verification": [~7,[2],[3]],
		"/(app)/auth/password-reset/callback": [8,[2],[3]],
		"/(app)/auth/password-reset/request": [~9,[2],[3]],
		"/(app)/auth/sign-in": [10,[2],[3]],
		"/(app)/auth/sign-up": [~11,[2],[3]],
		"/(app)/playground/protected-auth0": [~12,[2],[3]],
		"/(app)/playground/protected-better-auth": [~13,[2],[3]],
		"/(app)/playground/settings/(profile)": [~14,[2,4],[3]],
		"/(app)/playground/settings/account": [~15,[2,4],[3]],
		"/(app)/playground/settings/appearance": [~16,[2,4],[3]],
		"/(app)/playground/settings/display": [~17,[2,4],[3]],
		"/(app)/playground/settings/language": [18,[2,4],[3]],
		"/(app)/playground/settings/notifications": [~19,[2,4],[3]],
		"/(app)/repository/articles": [20,[2],[3]],
		"/(app)/repository/tasks": [~21,[2],[3]],
		"/(app)/repository/tasks/[id]": [~22,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';
import { env } from "$env/dynamic/public"
import { publicEnvKeys } from "$lib/publicEnvKeys"
import * as Sentry from "@sentry/sveltekit"
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit"
import type { HandleClientError } from "@sveltejs/kit"

const useSentry = env[publicEnvKeys.sentry.PUBLIC_SENTRY_ACTIVATE]
const dsn = env[publicEnvKeys.sentry.PUBLIC_SENTRY_DSN]
if (useSentry && !dsn) {
	console.error(`Missing environment variable: ${publicEnvKeys.sentry.PUBLIC_SENTRY_DSN}`)
}

let customHandleError: HandleClientError | undefined = undefined

function getErrorHandler(): HandleClientError {
	// Look up in cache
	if (customHandleError) {
		return customHandleError
	}

	// Construct the error handler and cache it
	if (useSentry && !dsn) {
		Sentry.init({
			dsn,

			tracesSampleRate: 1.0,

			// This sets the sample rate to be 10%. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: 0.1,

			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,

			// If you don't want to use Session Replay, just remove the line below:
			integrations: [replayIntegration()]
		})
		customHandleError = handleErrorWithSentry() as HandleClientError
	} else {
		customHandleError = () => {}
	}

	return customHandleError
}

export const handleError = getErrorHandler()
